.card :global(.ant-card-head) {
    font-size: 14px;
    padding: 0 1rem;
}

.card {
    margin-bottom: 1rem;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.assetInfo {
    display: flex;
    align-items: center;
    gap: .2rem;
}

.assetIcon > div:first-child img:first-child {
    width: 20px !important;
    height: 20px !important;
}

.assetIcon > div:first-child img:nth-child(2) {
    width: 15px !important;
    height: 15px !important;
    top: 43%;
    left: 60%;
}

.body {
    /*display: grid;*/
    /*grid-template-columns: repeat(2, 1fr);*/
}

.tabs {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    border-bottom: 2px solid #0c4cfc;
    margin-bottom: 1rem;
}

.tabs li {
    padding: 0.2rem 1rem;
    background-color: #d2e0fb;
    border-radius: 0.4rem 0.4rem 0 0;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    width: 80px;
    display: flex;
    justify-content: center;
}

.tabs li:hover,
.activeTab {
    background-color: #0c4cfc !important;
    color: white;
}

.infoBox {
    display: flex;
    align-items: baseline;
    gap: .5rem;
    font-family: Poppins-Medium, sans-serif;
    font-size: 13px;
}

.label {

}


.infoBox span:nth-child(2) {
    font-family: Poppins-Regular, sans-serif;
    color: #7f7f7f;
}

@media (max-width: 480px) {
    .assetInfoTitle {
        display: none;
    }
}