/** @format */

.mainBtn {
    background-color: #3c9cd6;
    color: white;
    border-radius: 0.3rem;
    border: 1px solid #3c9cd6;
    font-family: Poppins-Medium, serif;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    font-size: 14px;
    line-height: 1.5714285714285714;
    height: 32px;
    padding: 4px 15px;
    transition: 0.3s all ease-in-out;
}

.mainBtn:hover {
    background-color: white;
    color: #3c9cd6;
}

.title {
    display: flex;
    align-items: center;
}

.loader {
    width: 100%;
    position: relative;
}

.loader :global(.ant-skeleton),
.loader :global(.ant-skeleton-image) {
    width: 100% !important;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0.2rem;
}

.spinner p {
    font-size: 12px;
    color: #0c4cfc;
}

.loadingStatus {
    display: flex;
    gap: 0.2rem;
    align-items: center;
    /*margin-top: 1rem;*/
}

.loadingStatus p {
    color: #0c4cfc;
    font-size: 12px;
    margin-bottom: 0;
}

.box {
    margin-top: 1rem;
    position: relative;
}

.overlayMask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    background-color: rgba(255, 255, 255, 0.52);
}

.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    align-items: center;
}

.footerStatusbar {
    display: flex;
    align-items: center;
}

.handlerBtns {
    align-self: flex-end;
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.nextBtn {
    border-radius: 0.5rem;
    color: white;
    padding: 0.3rem 1rem;
    cursor: pointer;
    background-color: #f39c12;
    border: 1px solid #f39c12;
    transition: 0.3s ease-in-out all;
    font-family: Poppins-Medium, sans-serif;
}

.nextBtn:hover {
    background-color: white;
    color: #f39c12;
}

.nextBtn.disabled:hover {
    background-color: #f39c12;
    color: white;
}

/*--------------SELECT EXCHANGE-----------------*/

.retryBtn {
    cursor: pointer;
    text-decoration: underline;
    color: #0c4cfc;
}

.exchangeList {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0;
    flex-wrap: wrap;
    justify-content: center;
}

.exchange {
    width: 100px;
    background-color: rgba(187, 221, 244, 0.68);
    border-radius: 0.5rem;
    border: 2px solid transparent;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.2rem;
    font-size: 10px;
    font-family: Poppins-Medium, sans-serif;
}

.exchangeIcon img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
}

.exchange:hover,
.selectedExchange {
    border-color: #0c4cfc;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

.disabled {
    opacity: 0.8;
    cursor: not-allowed !important;
}

.disabled:hover {
    border-color: transparent;
    box-shadow: unset;
}

/*--------------SELECT EXCHANGE-----------------*/

/*---------------ASSET--------------------*/
.suggestedCoin {
    display: inline-flex;
    font-weight: 500;
    cursor: pointer;
    font-size: 12px;
    margin-left: 0.5rem;
}

/*---------------ASSET--------------------*/

/*----------------TRADE TYPE BOX---------------*/

.tradeTypeBtns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.tradeType {
    background-color: rgba(187, 221, 244, 0.68);
    border-radius: 0.5rem;
    cursor: pointer;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    padding: 0.3rem 1rem;
    font-family: Poppins-Medium, sans-serif;
    transition: 0.3s all ease-in-out;
}

.selectedTradeType,
.tradeType:hover {
    color: white;
    background-color: #0c4cfc !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

/*----------------TRADE TYPE BOX---------------*/

/*---------------SELECT ASSET---------------*/
.selectAsset {
    width: 100% !important;
    max-width: 300px !important;
}

/*---------------SELECT ASSET---------------*/

/*------------BALANCE WARNING------------*/
.balanceWarning {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    /*margin-top: 1rem;*/
    color: red;
}

.balanceWarning p {
    margin-bottom: 0;
    font-size: 13px;
    color: red;
}

/*------------BALANCE WARNING------------*/

/*---------------TRADE SIZE----------------*/
.tradeSizeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.backBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    padding: 0.2rem 0.5rem;
    background-color: #c4e1f6;
    /*color: white;*/
    border-radius: 1rem;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.backBtn:hover {
    opacity: 0.8;
}

.statusBar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    background-color: rgb(224, 244, 255);
    border-radius: 0.5rem;
    padding: 0.2rem 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 6px 0;
}

.statusBar > div {
    padding: 0.3rem 0.5rem;
    flex: 1;
    font-family: Poppins-Medium, serif;
}

.statusBar > div:first-child {
    border-right: 2px solid #0c4cfc;
}

.info,
.info span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.2rem;
}

.label {
    font-family: Poppins-Medium, serif;
}

.amount {
    color: grey;
}

.tradeTypeBtn {
    background-color: rgba(187, 221, 244, 0.68);
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 0.2rem;
    padding: 0.3rem 1rem;
    font-family: Poppins-Medium, sans-serif;
    transition: 0.3s all ease-in-out;
}

.tradeTypeBtn:hover {
    color: white;
    background-color: #0c4cfc !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

/*------------TRADE AMOUNT-------------*/
.setAmountBox {
    margin-top: 1rem;
    position: relative;
}

.amountInputs {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.inputBox {
    display: flex;
    /* align-items: center; */
    align-items: baseline;
    gap: 0.1rem;
}

.inputBox label {
    font-size: 12px;
    font-family: Poppins-Regular, serif;
}

.inputBox :global(.ant-input-number) {
    width: 100%;
    max-width: 120px;
}

.sliderBox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.2rem;
}

.sliderBox :global(.ant-slider) {
    flex: 3;
    max-width: 230px;
}

.sliderBox :global(.ant-input-number) {
    width: 70px;
}

/*------------TRADE AMOUNT-------------*/

/*-----------------BUY LIMIT------------------*/
.limitOptions {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-top: 1rem;
}

.limitBox {
    flex: 1;
}

.limitBoxHeader {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #0c4cfc;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    font-family: Poppins-Medium, serif;
}

.limitBoxBody {
    position: relative;
    margin-top: 0.5rem;
}

.input {
    width: 100% !important;
    max-width: 120px !important;
}

/*-----------------BUY LIMIT------------------*/

/*--------------AI SUGGESTION------------------*/
.suggestionContainer {
    margin-top: 1rem;
    padding: 0.8rem 1rem;
    border-radius: 0.5rem;
    /*border: 1px solid #00b894;*/
    position: relative;
    border: 1px solid #d9d9d9;
    max-width: 320px;
    width: 100%;
}

.suggestionIcon {
    position: absolute;
    left: 1%;
    top: -7%;
    /*background-color: white;*/
    /*width: 30px;*/
    /*height: 30px;*/
}

.suggestionBtns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.suggestionHandlers {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.cancelSuggestion,
.retrySuggestion {
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    padding: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cancelSuggestion {
    background-color: rgba(187, 221, 244, 0.68);
}

.cancelSuggestion:hover {
    background-color: rgba(91, 186, 250, 0.68);
}

.retrySuggestion {
    background-color: #0c4cfc;
}

.retrySuggestion:hover {
    background-color: #0232b4;
}

.aiBtn {
    background-color: #00b894 !important;
    color: white !important;
    border-color: #00b894 !important;
    border-radius: 1rem !important;
}

.aiBtn:not(:disabled):hover,
.aiBtn:not(:disabled):focus,
.aiBtn:not(:disabled):active {
    background-color: white !important;
    color: #00b894 !important;
}

.aiBtn:disabled {
    opacity: 0.6 !important;
}

.noSuggestion :global(.ant-empty-image) {
    height: 50px;
    margin-bottom: 0;
}

.suggestionInfo {
    margin-top: 0.5rem;
    font-family: Poppins-Regular, serif;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.applyAllSuggestion {
    padding: 0.2rem 1rem;
    background-color: #1abc9c;
    color: white;
    border-radius: 1rem;
    cursor: pointer;
    border: 1px solid #1abc9c;
    transition: 0.3s all ease-in-out;
}

.applyAllSuggestion:hover {
    background-color: white;
    color: #1abc9c;
}

.applySuggestion {
    background-color: #dcf0ff;
    color: #0c4cfc;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    padding: 0.1rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 11px;
    display: inline-flex;
    align-items: center;
    gap: 0.2rem;
    width: fit-content;
    font-family: Poppins-Regular, serif;
}

.applySuggestion:hover {
    background-color: #0c4cfc;
    color: white;
}

/*--------------AI SUGGESTION------------------*/

/*---------------TRADE SIZE----------------*/

/*-------------TRADE HANDLERS---------------*/
.quickOrderBtn {
    background-color: #0c4cfc;
    border-radius: 0.5rem;
    color: white;
    padding: 0.3rem 1rem;
    cursor: pointer;
    border: 1px solid #0c4cfc;
    transition: 0.3s ease-in-out all;
    font-family: Poppins-Medium, sans-serif;
}

.quickOrderBtn:not(.disabled):hover {
    color: #0c4cfc;
    background-color: white;
}

/*-------------TRADE HANDLERS---------------*/

/*------------RESULT-------------*/
.resultBox {
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.successMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.actionSuggestion {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.actionSuggestion span {
    border-radius: 0.4rem;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    padding: 0.3rem 1rem;
    font-family: Poppins-Regular, serif;
}

.actionSuggestion span:hover {
    opacity: 0.7;
}

.portfolioBtn {
    background-color: #28a745;
    color: white;
}

.tradeBtn {
    background-color: #007bff;
    color: white;
}

/*------------RESULT-------------*/

@media (max-width: 768px) {
    .exchange {
        flex-direction: row;
        gap: 0.2rem;
        width: 110px;
    }

    .exchangeIcon img {
        width: 15px;
        height: 15px;
    }
}

@media (max-width: 480px) {
    .setAmountBox,
    .box,
    .suggestionContainer {
        margin-top: 0.5rem;
    }

    .suggestionContainer {
        padding: 0.5rem;
    }

    .box :global(.ant-tabs-nav) {
        margin-bottom: 0.5rem;
    }

    .box :global(.ant-tabs-tab) {
        padding: 0.5rem 0;
    }

    .statusBar {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 0.2rem;
    }

    .statusBar > div:first-child {
        border-right: 0;
        border-bottom: 2px solid #0c4cfc;
        width: 100%;
        justify-content: flex-start;
        padding: 0.5rem 0.3rem;
    }

    .statusBar > div,
    .tradeTypeBtn,
    .aiBtn,
    .applyAllSuggestion {
        font-family: Poppins-Regular, serif;
        font-size: 12px !important;
    }

    .aiBtn {
        padding: 0.2rem 1rem !important;
        height: 30px !important;
    }

    .tradeTypeBtn {
        padding: 0.3rem 0.5rem;
    }

    /*.sliderBox :global(.ant-input-number) {*/
    /*    width: 50px;*/
    /*}*/
}
