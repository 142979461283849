.container {
    background-color: #ffffff;
    padding: 15px 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    border: none;
    box-shadow: 0 3px 8px rgba(15, 28, 63, 0.1);
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.title {
    margin-bottom: 5px;
    font-size: 1.7em;
    color: #0f1c3f;
    font-family: Poppins-Bold, sans-serif;
}

.serviceInfo {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.serviceStatus {
    background-color: #2a86ff;
    color: white;
    padding: 8px 12px;
    border-radius: 999px;
    font-size: 0.9em;
    display: inline-flex;
    align-items: center;
}

.serviceExpiration {
    color: white;
    font-size: 0.9em;
    background-color: #1B2548;
    border-radius: 999px;
    padding: 8px 12px;
}
