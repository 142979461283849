.container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #b0b8c6;
    /*justify-content: space-between;*/
    align-items: center;
    box-shadow: 0 2px 4px rgba(15, 28, 63, 0.05);
}

/*.button {*/
/*    padding: 0.4rem 0.7rem;*/
/*    font-size: 14px;*/
/*    background-color: #d2e0fb;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    border-radius: 1rem;*/
/*    color: #2f3542;*/
/*    cursor: pointer;*/
/*    transition: 0.3s all ease-in-out;*/
/*}*/

/*.button:hover {*/
/*    background-color: #0c4cfc;*/
/*    color: white;*/
/*}*/

.button {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 999px;
    font-size: 0.9em;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    color: #0f1c3f;
    border: 1px solid #b0b8c6;
    padding: 8px 15px;
    gap: .3rem;
    box-shadow: 0 2px 4px rgba(15, 28, 63, 0.1);
}

.button:hover {
    border-color: #2a86ff;
    background-color: #F5F8FF;
}

.button.displayOnly {
    padding: 0;
}

.button.displayOnly a {
    color: #0f1c3f;
    line-height: inherit;
    display: flex;
    gap: .3rem;
    padding: 8px 15px;
    /*cursor: default;*/
}

.button :global(.ant-btn) {
    padding: 0;
    border: none;
    background: transparent;
    box-shadow: none;
    height: auto;
    line-height: unset;
    font-size: inherit;
    color: #2f3542 !important;
}

.button :global(.ant-btn):not(:disabled):hover,
.button :global(.ant-btn):not(:disabled):focus,
.button :global(.ant-btn):not(:disabled):active {
    background-color: #F5F8FF !important;
    /*background: unset !important;*/
    /*color: white !important;*/
}

/*.button:hover :global(.ant-btn) {*/
/*    color: white !important;*/
/*}*/

.status {
    color: #2a86ff;
}

/*@media (max-width: 480px) {*/
/*    .label {*/
/*        display: none;*/
/*    }*/
/*}*/
