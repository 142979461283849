.container {
    display: flex;
    flex-direction: column;
    min-width: 270px;
}

.header {
    border-bottom: 1px solid #0f1c3f;
    padding-bottom: .3rem;
}

.infoItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Poppins-Medium, sans-serif;
    margin-bottom: .3rem;
    flex-wrap: wrap;
}

.info {
    color: #2A86FF;
}
