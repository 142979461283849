.container
  width: 100%
//padding: 20px

.header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 20px

  .countdown
    display: flex
    align-items: center
    gap: 10px

    img
      width: 30px
      margin-right: 0.3rem

    p
      margin: 0
      display: flex
      align-items: center
      gap: .2rem
      flex-wrap: wrap

.cardsGrid
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
  gap: 20px
  padding: 0 10px

.card
  border: 1px solid #B0B8C6
  border-radius: 8px
  padding: 1rem !important
  //background: white
  display: flex
  flex-direction: column
  gap: 16px
  font-family: Poppins-Regular, sans-serif
  transition: .3s ease-in-out transform
  box-shadow: 0 5px 15px rgba(15, 28, 63, 0.08)

  &:hover
    transform: translateY(-5px)
    border-color: #2A86FF
    box-shadow: 0 8px 25px rgba(15, 28, 63, 0.12)

.otherCoins
  border: 2px dashed #B0B8C6
  justify-content: center
  align-items: center
  border-spacing: .3rem
  transition: .3s ease-in-out all
  color: #B0B8C6
  font-style: italic

  h3
    font-weight: 700

  p
    color: #B0B8C6

  &:hover
    color: #2A86FF

    p
      color: #2A86FF

  .otherCoinsBtn
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    cursor: pointer

    p
      text-align: center

.cardHeader
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 8px

  .asset
    > div:last-child
      font-family: Poppins-Bold, sans-serif
      font-size: 17px
      margin-left: .2rem

  .symbolInfo
    h3
      font-size: 18px
      font-weight: 600
      margin: 0

.tradeButton
  padding: 8px 24px
  border-radius: 4px
  font-weight: 500
  border: none
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  gap: .5rem

  &.buy
    background: #2ECB71
    color: white

  &.sell
    background: #E74C3C
    color: white

  &.hold
    background: #2A86FF
    color: white

.signalSection
  display: flex
  flex-direction: column

.signal
  display: flex
  flex-wrap: wrap
  gap: 4px
  margin-bottom: .5rem
  background: #f8f9fa
  padding: 12px
  border-radius: 6px

  .signalLabel
    font-weight: 500
    color: #666

  .signalInfo
    display: flex
    align-items: center
    gap: 6px
    font-size: 14px
    flex-wrap: wrap

    .signalType
      font-weight: 500

      &.buyStatus
        color: #2ECB71

      &.sellStatus
        color: #E74C3C

      &.holdStatus
        color: #2A86FF

    .confidence
      color: #666

    .time
      color: #999


.strongSignal
  background: #eef9ff
  position: relative

  .signalLabel
    font-size: 16px
    color: #0d0d0d

  &::before
    content: ""
    height: 100%
    width: 3px
    background-color: #0C4CFC
    border-radius: 8px 0 0 8px
    position: absolute
    left: 0
    top: 0

.poorSignal
  font-style: italic

.infoSection
  display: grid
  gap: 8px

//> div
//  display: flex
//  justify-content: space-between
//  align-items: center
//
//  > span:first-child
//    color: #666
//    font-weight: 500
//
//  > span:last-child
//    font-weight: 500

.runAiButton
  width: 100%
  padding: .5rem 1rem
  background: #0F1C3F
  border: none
  border-radius: 4px
  color: white
  font-weight: 500
  cursor: pointer
  margin-top: auto
  transition: all 0.2s
  box-shadow: 0 3px 6px rgba(15, 28, 63, 0.15)
  display: flex
  align-items: center
  justify-content: center
  gap: .3rem

  &:hover:not(:disabled)
    background-color: #1B2548
    box-shadow: 0 4px 10px rgba(15, 28, 63, 0.2)

  &:disabled
    cursor: not-allowed
    opacity: .5

.priceSection
  margin-top: 15px

  .currentPrice
    margin-bottom: 15px

    h4
      display: flex
      align-items: center
      gap: 8px
      margin-bottom: 5px

      svg
        cursor: pointer

        &:hover
          opacity: 0.7

.limits
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 15px

  h4
    margin-bottom: 5px
    font-size: 14px
    color: #666

.buy
  background: #2ECB71
  color: white
  padding: 10px 12px
  border-radius: 4px
  font-weight: 500
  transition: .3s all ease-in-out

  &:hover
    background: #27AE60
    transform: translateY(-2px)
    box-shadow: 0 4px 10px rgba(15, 28, 63, 0.2)

.sell
  background: #E74C3C
  color: white
  padding: 10px 12px
  border-radius: 4px
  font-weight: 500
  transition: .3s all ease-in-out

  &:hover
    background: #C0392B
    transform: translateY(-2px)
    box-shadow: 0 4px 10px rgba(15, 28, 63, 0.2)

.hold
  background: #2A86FF
  color: white
  padding: 10px 12px
  border-radius: 4px
  font-weight: 500
  transition: .3s all ease-in-out

  &:hover
    background: #1B6BD3
    transform: translateY(-2px)
    box-shadow: 0 4px 10px rgba(15, 28, 63, 0.2)

.hideContent
  padding: 0
  cursor: pointer
  background-color: #bdc3c7 !important
  border-radius: .3rem

.timeAgo
  font-size: 12px
  color: #666
  margin-top: 5px

.infoItem
  display: flex
  justify-content: space-between
  align-items: center
  gap: 8px

  :global
    .ant-skeleton-element
      flex: 1
      width: 50%

    .ant-skeleton-input
      min-width: 100% !important


.skeleton
  width: 100%
  height: 100%
  background: #f0f0f0
  border-radius: 4px
  animation: pulse 1.5s infinite

  div
    width: 100%
    height: 100%

    span
      display: block
      width: 100%
      height: 100%
      background: #e0e0e0
      border-radius: 4px

.info
  display: flex
  align-items: center
  gap: 1rem
  margin-bottom: .5rem

  > span:first-child
    color: #0F1C3F
    font-weight: 600

  > span:last-child
    color: #0F1C3F

.categoryTooltip
  display: inline-flex
  align-items: center
  gap: .2rem

@keyframes pulse
  0%
    opacity: 0.6
  50%
    opacity: 0.8
  100%
    opacity: 0.6

@media (max-width: 768px)
  .countdown
    display: none !important