.container {
    display: grid;
    /* flex-direction: column; */
    /*justify-content: space-evenly;*/
    /*align-items: center;*/
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 1rem;
}

.textBox {
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    flex-direction: column;
    background-color: #c5dcfa;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05),
    5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05);
    flex: 1;
}

/*.textBox > * {*/
/*    margin-bottom: 0 !important;*/
/*}*/

.textBoxTitle {
    font-weight: 400;
    margin-right: 0.5rem;
    color: #2b303a;
    font-family: Poppins-Medium;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .2rem;
}

.textBoxInfo, .plansList {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
}

.info {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
}

.subscriptionList {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    /*gap: .5rem;*/
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    margin: auto;
}

.plansList {
    flex-direction: column;
    gap: .1rem;
    align-items: flex-start;
    /*margin-bottom: 0;*/
    margin: auto;
}

.textBoxInfoTitle {
    margin-left: 0.3rem;
    font-size: 14px;
    margin-bottom: 0;
    /*text-align: center;*/
}

.morePlan {
    margin-left: .5rem;
    font-size: 13px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: .2rem;
    font-weight: 500;
    color: #0C4CFC;
    cursor: pointer;
}

.loader,
.message {
    margin-bottom: 0.5rem;
}

.tierBox {
    position: relative;
}

.tierBtn {
    position: absolute;
    top: 16px;
    right: 16px;
    /*animation: pulse 1.5s infinite;*/
}

.infoIcon {
    margin-left: 8px;
    color: #fa8c16;
    cursor: pointer;
    transition: transform 0.3s ease, color 0.3s ease;
}

.infoIcon svg {
    animation: pulse 1.5s infinite ease-in-out;
    border-radius: 100%;
}

.infoIcon:hover {
    transform: scale(1.2);
    color: #ffc069;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0px rgba(24, 144, 255, 0.7);
    }
    50% {
        box-shadow: 0 0 10px rgba(24, 144, 255, 1);
    }
    100% {
        box-shadow: 0 0 0px rgba(24, 144, 255, 0.7);
    }
}

/*@keyframes pulse {*/
/*    0% {*/
/*        transform: scale(1);*/
/*    }*/
/*    50% {*/
/*        transform: scale(1.1);*/
/*    }*/
/*    100% {*/
/*        transform: scale(1);*/
/*    }*/
/*}*/


@media (min-width: 1500px) {
    .textBoxTitle,
    .textBoxInfoTitle {
        font-size: 1.2rem;
    }
}

@media (min-width: 1260px) and (max-width: 1500px) {
    .textBoxTitle,
    .textBoxInfoTitle {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .textBox {
        padding: 12px;
    }

    .tierBtn {
        top: 12px;
        right: 12px;
    }
}

@media (max-width: 1000px) {
    .container {
        grid-template-columns: repeat(2, 1fr);
    }

    .container > div:last-child {
        grid-column: 1/span 2;
    }

    /*.textBox:last-child {*/
    /*    grid-column: span 2;*/
    /*}*/
}

@media (max-width: 468px) {
    .container {
        gap: 14px;
    }
}
