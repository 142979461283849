@import "../../variables"
@import "../../mixins"
.container
  width: 100%
  display: flex
  justify-content: center
  flex-direction: row-reverse
  gap: 36px

.deleteAccountBox
  display: flex
  flex-direction: column

.deleteAccountBox
  p
    span
      color: red

.deleteBtn
  display: flex !important
  background-color: #2b5fec !important
  align-items: center !important
  justify-content: center !important
  border-color: #2b5fec !important
  align-self: center !important
  padding: .3rem 1.3rem !important

.sideBar
  width: 25%
  height: 550px
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05)
  border-radius: 9px
  margin-top: 50px
  padding: 16px

  > div
    &:nth-of-type(1)
      display: flex
      align-items: center
      margin-bottom: 36px
      gap: 8px
      padding-bottom: 20px
      border-bottom: 1px solid rgba(104, 103, 103, 0.37)

      > svg
        font-size: 20px
        color: #0C4CFC

      > h3
        font-size: 20px
        margin: 0

  > ul
    display: flex
    flex-direction: column
    gap: 18px
    //border-bottom: 1px solid rgba(104, 103, 103, 0.37)

    > li
      width: 100%
      border-radius: 8px
      transition: all .2s linear
      height: 48px
      @include fullFlex

      > a
        font-size: 18px !important
        color: black !important
        width: 100%
        padding: 16px 16px

      &:hover
        background-color: rgba(197, 220, 250, 0.38)

    .activeSection
      background-color: #c5dcfa

.mainContent
  width: 70%
  min-height: 100%
  //max-height: 100vh
  border-radius: 8px
  margin-top: 50px
  padding: 16px
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05)

  .profileImg
    width: 100%
    @include fullFlex

    > div
      position: relative

      > img
        width: 200px
        height: 200px
        border-radius: 100%
        border: 5px solid white
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1), 0 -5px 5px rgba(0, 0, 0, 0.1), 5px 0 5px rgba(0, 0, 0, 0.1), -5px 0 5px rgba(0, 0, 0, 0.1)


      > span
        cursor: pointer
        position: absolute
        bottom: 10px
        right: 10px
        height: 40px
        width: 40px
        @include fullFlex
        background-color: white
        border-radius: 100%
        border: 2px solid #0C4CFC

        > span
          height: 20px

        svg
          font-size: 18px
          color: #0C4CFC

      .loader
        position: absolute
        top: 0
        left: 0
        display: flex
        justify-content: center
        align-items: center
        background-color: rgba(137, 170, 255, 0.46)
        width: 100%
        height: 100%
        border-radius: 100%

  .editButton
    width: 100%
    display: flex
    justify-content: flex-end

    > button
      width: 100%
      max-width: 170px
      display: flex
      justify-content: flex-end
      color: white !important
      font-weight: 700
      background-color: #547ef1 !important
      @include fullFlex
      gap: 8px

      &:hover
        background-color: white !important
        color: #1b4aef !important

      svg
        font-size: 20px !important

  .profileInfo
    width: 100%
    display: flex
    flex-wrap: wrap
    gap: 24px
    margin-top: 36px
    justify-content: center
    padding: 4px

    > div
      width: 100%
      display: flex
      gap: 16px
      padding: 16px
      border-radius: 8px
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05)
      align-items: center

      > span
        &:nth-of-type(2)
          @include fontNormal
          font-size: 18px

        &:nth-of-type(1)
          color: $Mortar
          font-size: 18px

  .form
    width: 100%
    display: flex
    margin-top: 16px
    flex-wrap: wrap
    gap: 24px
    justify-content: center

    input
      height: 45px
      border-radius: 8px

    //width: 45%
    .input
      //width: 47%
      border: 1px solid #4972e5
      min-height: 48px

      svg
        color: #0C4CFC

    .formItem
      width: 100%

  .budgetContainer
    width: 100%

    .description
      margin-top: .5rem
      font-size: 1rem

    > div
      &:nth-of-type(1)
        > span
          @include fontNormal
          font-size: 18px

        > div
          width: 100%
          display: flex
          justify-content: center
          margin: 30px 0
          gap: 36px

          > span
            width: 40%
            @include fullFlex
            height: 55px
            @include fontNormal
            font-size: 20px
            transition: all .2s linear
            border: 2px solid #4972e5
            border-radius: 8px
            cursor: pointer

            &:hover
              transform: scale(1.005)


        .activePortfolio
          background-color: #4972e5
          color: white

    .budget
      width: 100%
      margin-top: 36px

      > p
        margin: 10px 0
        font-size: 16px

      > span
        color: #666666

    .btn
      width: 100%
      @include fullFlex
      margin-top: 36px

  .demoContainer
    width: 100%

    > div
      width: 100%

      > span
        @include fontNormal

      > div
        margin-top: 36px
        display: flex
        flex-direction: column
        gap: 24px

        > div
          display: flex
          gap: 24px
          align-items: center

          > Span
            @include fontNormal

    .btn
      margin-top: 40px
      @include fullFlex

.infoModal
  .form
    width: 100%
    display: flex
    margin-top: 16px
    flex-wrap: wrap
    justify-content: center

    input
      height: 45px
      border-radius: 8px

    //width: 45%
    .input
      //width: 47%
      border: 1px solid #4972e5
      min-height: 48px

      svg
        color: #0C4CFC

    .formItem
      width: 100%

    .budgetContainer
      width: 100%

      > div
        &:nth-of-type(1)
          > span
            @include fontNormal
            font-size: 18px

          > div
            width: 100%
            display: flex
            margin-top: 40px
            gap: 36px

            > span
              width: 49%
              @include fullFlex
              height: 55px
              @include fontNormal
              font-size: 20px
              transition: all .2s linear
              border: 2px solid #4972e5
              border-radius: 8px
              cursor: pointer

              &:hover
                transform: scale(1.005)


          .activePortfolio
            background-color: #4972e5
            color: white

      .budget
        width: 100%
        margin-top: 36px

      .btn
        width: 100%
        @include fullFlex
        margin-top: 36px

    .demoContainer
      width: 100%

      > div
        width: 100%

        > span
          @include fontNormal

        > div
          margin-top: 36px
          display: flex
          flex-direction: column
          gap: 24px

          > div
            display: flex
            gap: 24px
            align-items: center

            > Span
              @include fontNormal

      .btn
        margin-top: 40px
        @include fullFlex

.avatar
  width: 200px !important
  height: 200px !important

  span
    font-size: 50px !important

.riskAmount
  width: 100%
  display: flex
  flex-direction: column
  gap: 20px
  align-items: center

  .riskTitle
    font-family: Poppins-Medium
    font-weight: 400
    line-height: 1.5rem
    color: #2B303A
    font-size: 18px
    align-self: flex-start

  .riskDescription
    align-self: flex-start
    font-size: 1rem

  > ul
    svg
      font-size: 40px

  .rateAmount
    width: 100%
    @include fullFlex
    @include fontNormal
    color: #1d39c4

  .riskNote
    width: 95%
    align-self: flex-start

.plan
  position: absolute
  top: 0
  left: 0

.currentBudget
  text-align: center
  font-family: Poppins-Regular
  font-size: 16px
  color: #666666

.usdSymbol
  margin-left: .2rem

.aiAssistantHeader
  display: flex
  justify-content: space-around
  align-items: center
  margin-bottom: 1rem

.aiTab
  width: 100%
  //text-align: center
  border-bottom: 1px solid lightgray
  padding: 1rem
  font-size: 20px
  color: #7f8c8d
  cursor: pointer
  transition: .2s all
  font-family: Poppins-Medium
  font-weight: 400


.aiAssistantHeader > li.active
  color: #2B303A


.aiAssistantHeader > li.seprator
  width: 2px
  border: none
  background-color: lightgray
  height: 30px

.exchanges
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  padding: 2rem 0

.dangerZone
  position: relative

  &::after
    position: absolute
    display: block
    content: "Danger Zone"
    background-color: red
    border-radius: .8rem
    top: -10%
    right: 10%
    font-size: 12px
    color: white
    padding: 0 .5rem

// @media (max-width: 1200px)  
//   .container
//     margin-bottom: 3rem

@media (min-width: 895px) and (max-width: 1000px)
  .sideBar
    width: 30%

@media (max-width: 895px)
  .container
    flex-direction: column
    gap: 25px
  .sideBar
    margin-top: 0
    width: 100%
    height: auto

    > ul
      display: grid
      grid-template-columns: repeat(3, 1fr)
      gap: 10px

      > li
        > a
          font-size: 16px !important
          text-align: center

    > div
      &:nth-of-type(1)
        margin-bottom: 16px

    > ul
      flex-direction: row
      //gap: 0
      justify-content: space-between
      margin-bottom: 0

      > li
        width: auto
  .mainContent
    width: 100%
    margin-top: 0

@media (min-width: 645px) and (max-width: 700px)
  .sideBar
    > ul
      > li
        > a
          font-size: 16px !important

@media (min-width: 570px) and (max-width: 645px)
  .sideBar
    > ul
      > li
        > a
          font-size: 14px !important
          padding: 12px !important

@media (max-width: 680px)
  .aiTab
    font-size: 14px
    padding: 1rem .5rem
  .aiTabPrefix
    display: none
  .riskAmount
    .riskTitle
      font-size: 16px

@media (max-width: 570px)
  .sideBar
    > ul
      display: grid
      grid-template-columns: repeat(2, 1fr)
      gap: 10px

      > li
        > a
          font-size: 16px !important
          text-align: center
  .mainContent
    .profileInfo
      > div
        > span
          &:nth-of-type(2)
            font-size: 16px

          &:nth-of-type(1)
            font-size: 16px

    .budgetContainer
      > div
        &:nth-of-type(1)
          > div
            gap: 10px

            > span
              font-size: 16px

          > span
            font-size: 16px

@media (max-width: 440px)
  .sideBar
    > ul
      display: grid
      grid-template-columns: repeat(1, 1fr) !important
      gap: 10px
  .mainContent
    .budgetContainer
      > div
        &:nth-of-type(1)
          > div
            > span
              width: 45%
              font-size: 14px !important

          > span
            font-size: 14px !important

    .riskAmount
      .riskTitle
        font-size: 14px !important

    .profileInfo
      > div
        flex-direction: column
        gap: 10px
        align-items: flex-start

  .aiTab
    font-size: 14px !important       
  