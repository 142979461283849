@import "../../variables"
@import "../../mixins"
.vedioPlayerWrapper
  width: 100%
  height: 100vh
  position: fixed
  top: 0
  left: 0
  @include fullFlex
  flex-direction: column
  z-index: 10000
  background-color: rgba(0, 0, 0, 0.5)

.hide
  display: none

.closeBtn
  //position: absolute
  //top: 10%
  //left: 85%
  font-size: 1rem
  font-weight: 700
  cursor: pointer
  background-color: white
  border-radius: 100%
  padding: 8px
  width: 30px
  height: 30px
  align-self: flex-end
  margin-right: 5rem
  @include fullFlex

h1
  position: absolute
  top: 8%
  left: 20%
  background-color: white
  border-radius: 8px
  padding: 8px 16px

@media (min-width: 1200px)
  .vedioPlayerWrapper
    width: calc(100% - 230px)
    margin-left: 230px

@media (max-width: 768px)
  .closeBtn
    align-self: center
    width: 30px
    height: 30px
    font-size: 1rem
    margin-bottom: 1rem
    margin-right: 0
