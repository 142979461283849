.container {
    position: fixed;
    right: 4.2rem;
    bottom: 9rem;
    z-index: 10;
}

.demoBtn {
    position: fixed;
    bottom: 6rem;
    right: 4rem;
    background-color: #0C4CFC;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: white;
    transition: .3s all;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    border: 2px solid #0C4CFC;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    /*animation: iconAnimation 1.2s infinite;*/
    /*z-index: 100;*/
    z-index: 2;
}

.demoBtn:hover {
    background-color: white;
    color: #0C4CFC;
}

.demoBtn::after {
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 6px solid #6dacfb;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
    box-sizing: border-box;
}

.demoBtn.active::after {
    animation: none !important;
    display: none !important;
}

.active {
    background-color: white;
    color: #0C4CFC;
}

.demoBtn:hover::after, .active::after {
    animation: none !important;
    display: none !important;
}

@keyframes ring {
    0% {
        /*width: 30px;*/
        /*height: 30px;*/
        /*opacity: 0;*/
        width: 70px;
        height: 70px;
        opacity: 1;
    }
    /*20% {*/
    /*    width: 80px;*/
    /*    height: 80px;*/
    /*    opacity: .5;*/
    /*}*/
    100% {
        width: 110px;
        height: 110px;
        opacity: 0;
    }
}


@keyframes iconAnimation {
    0% {
        transform: scale(1.1);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
    25% {
        transform: scale(1);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
    }
    50% {
        transform: scale(1.1);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
    75% {
        transform: scale(1);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
    }
    100% {
        transform: scale(1.1);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
}

.content {
    transition: .3s all ease-in-out;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    transform: translate(-3px, 15px);
}

.close {
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.open {
    opacity: 1;
    /*min-height: 200px;*/
    height: 100px;
}

.option {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0C4CFC;
    border: 2px solid #0C4CFC;
    color: white;
    border-radius: 100%;
    box-sizing: border-box;
    transition: .3s all ease-in-out;
    cursor: pointer;
}

.option:hover {
    background-color: white;
    color: #0C4CFC;
}

@media (min-width: 1200px) {
    .demoBtn {
        bottom: 4rem;
    }

    .container {
        bottom: 9rem;
    }
}

@media (max-width: 1200px) {

    .container {
        right: 3.5rem;
    }

    .demoBtn {
        width: 40px;
        height: 40px;
    }

    .demoBtn svg {
        width: 25px !important;
    }

    .content {
        transform: translate(-7px, -3px);
    }
}


@media (max-width: 480px) {
    .container {
        display: none;
    }
}

/*@media (max-width: 1200px) {*/
/*    .container {*/
/*        bottom: calc(6rem + 4.5rem) !important;*/
/*        right: 4rem !important;*/
/*    }*/
/*}*/