@import "../../variables"
@import "../../mixins"


.timeLine
  list-style-type: none
  display: flex
  justify-content: center
  margin-top: 1rem


.timeItem
  transition: all 200ms ease-in
  width: 180px

  h4
    font-weight: 600

.complete
  h4
    color: #0C4CFC

  .status
    border-top: 2px solid #0C4CFC

    &:before
      background-color: #0C4CFC
      border: none
      transition: all 200ms ease-in

.timeStamp
  margin-bottom: 20px
  padding: 0 30px
  display: flex
  flex-direction: column
  align-items: center
  font-weight: 600

.status
  //padding: 0px 40px
  padding: 0 1rem
  display: flex
  justify-content: center
  border-top: 2px solid #D6DCE0
  position: relative
  transition: all 200ms ease-in

  span
    margin-top: 1rem

  &:before
    content: ''
    width: 25px
    height: 25px
    background-color: white
    border-radius: 25px
    border: 1px solid #ddd
    position: absolute
    top: -15px
    left: 42%
    transition: all 200ms ease-in

.summary
  margin-left: 1rem
  margin-bottom: 2rem

  > p
    margin-left: 0 !important

.main
  width: 100%
  //max-width: 850px
  margin: 50px auto 50px auto
  position: relative

  .title
    font-size: 1.2rem
    font-family: Poppins-Medium, serif
    font-weight: 400
    line-height: 1.5rem
    color: #2B303A
    margin-bottom: 16px

  > div
    width: 100%

.overViewWrapper
  .overView
    width: 100%
    display: flex
    flex-direction: column

    > div
      width: 100%

      > h3
        @include fontNormal
        margin-bottom: 16px

      > p
        margin-left: 36px
        font-size: 1rem

        > span
          color: #0C4CFC

  .nextBtn

    display: flex !important
    justify-content: flex-end !important

    > button
      width: 256px
      height: 48px
      @include fontNormal
      background-color: #0C4CFC !important
      color: white !important
      transition: all .2s linear

      &:hover
        background-color: white !important
        color: #0C4CFC !important

.settingBtns
  display: flex
  justify-content: center
  gap: 1rem .5rem
  flex-wrap: wrap

  > button
    width: 256px
    height: 48px
    @include fontNormal
    transition: all .2s linear
    color: white !important

    > a
      @include fontNormal
      display: flex
      align-items: center
      justify-content: center
      gap: .2rem

  .reviseBtn
    background-color: #0C4CFC !important
    display: flex !important
    align-items: center !important
    justify-content: center !important
    gap: .2rem

    &:hover
      background-color: white !important
      color: #0C4CFC !important
      border-color: #0C4CFC !important

  .telegramBtn
    background-color: #2694cc !important

    &:hover
      background-color: white !important
      color: #2694cc !important
      border-color: #2694cc !important

  .discordBtn
    background-color: #80669d !important

    &:hover
      background-color: white !important
      color: #80669d !important
      border-color: #80669d !important

.stepOne

  .timePickers
    width: 100%
    display: flex
    flex-direction: column
    gap: 25px

    > div
      display: flex
      width: 100%
      align-items: center

  .nextBtn
    display: flex !important
    justify-content: flex-end !important

    > button
      width: 256px
      height: 48px
      @include fontNormal
      background-color: #0C4CFC !important
      color: white !important
      transition: all .2s linear

      &:hover
        background-color: white !important
        color: #0C4CFC !important

.stepTwo
  > div
    &:nth-of-type(1)
      width: 100%
      display: flex
      flex-direction: column
      align-items: flex-start
      gap: 36px

      > div
        width: 100%
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        gap: 16px 16px

        > span
          width: 100%
          padding: 16px
          background-color: rgba(187, 221, 244, 0.68)
          border-radius: 16px
          @include fullFlex
          @include fontNormal
          font-size: 18px
          transition: all .2s linear
          cursor: pointer

          &:hover
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05)

        .activeFrequency
          background-color: #0C4CFC
          color: white

      > span
        @include fontNormal
        font-size: .875rem
        min-width: fit-content
        @include fullFlex

  .nextBtn
    display: flex
    justify-content: flex-end

    > button
      width: 256px
      height: 48px
      @include fontNormal
      background-color: #0C4CFC !important
      color: white !important
      transition: all .2s linear

      &:hover
        background-color: white !important
        color: #0C4CFC !important

.stepThree
  ul
    width: 100%
    @include fullFlex

    li
      font-size: 60px !important

  > div
    &:nth-of-type(1)
      width: 100%
      display: flex
      flex-direction: column
      align-items: flex-start
      gap: 36px


      > span
        @include fontNormal
        color: #666666
        font-size: .875rem
        min-width: fit-content
        @include fullFlex

    .rateAmount
      width: 100%
      @include fullFlex
      @include fontNormal
      color: #1d39c4

  .nextBtn
    display: flex
    justify-content: flex-end

    > button
      width: 256px
      height: 48px
      @include fontNormal
      background-color: #0C4CFC !important
      color: white !important
      transition: all .2s linear

      &:hover
        background-color: white !important
        color: #0C4CFC !important

.stepFour
  > div
    &:nth-of-type(1)
      margin-bottom: 36px
      display: flex
      align-items: center

      gap: 24px

      > span
        @include fontNormal
        font-size: .875rem


    &:nth-of-type(2)
      width: 100%
      display: flex
      flex-direction: column
      align-items: flex-start
      gap: 36px

      > span
        @include fontNormal
        font-size: .875rem
        min-width: fit-content
        @include fullFlex

    .selectedPercentage
      @include fontNormal

      > span
        margin-left: 16px
        color: #1d39c4
        font-size: 18px

  .nextBtn
    flex-direction: row !important
    display: flex !important
    justify-content: flex-end !important

    > button
      width: 256px
      height: 48px
      @include fontNormal
      background-color: #0C4CFC !important
      color: white !important
      transition: all .2s linear
      @include fullFlex

      &:hover
        background-color: white !important
        color: #0C4CFC !important

.buttons
  display: flex
  flex-direction: row !important
  align-items: center
  justify-content: flex-end
  margin-top: 50px

.back
  margin-right: 2rem
  font-size: 16px

  display: block

  top: -60px
  cursor: pointer
  color: #0C4CFC
  @include fullFlex


.tableContent
  @include fontNormal

  //display: flex
  //align-items: center
  img
    width: 40px
    height: 40px
    border-radius: 100%
    margin-right: 16px

.table
  margin-top: 24px

  th
    color: blue !important
    font-size: 0.875rem !important
    padding: 16px 8px !important
    background-color: rgba(150, 171, 234, 0.25) !important

.timeZoneBtn
  position: absolute
  top: 100px
  right: 17%
  padding: 8px 16px
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05)
  border-radius: 8px
  //min-width: 200px

  > a
    > span
      white-space: nowrap
      //width: 150px
      //width: 60px
      overflow: hidden
      text-overflow: ellipsis

.MenuClassName
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.09), 5px 0 5px rgba(0, 0, 0, 0.09), -5px 0 5px rgba(0, 0, 0, 0.09)
  border-radius: 8px !important
  width: 100% !important
  margin-top: 10px !important
  padding: 8px !important
  left: -5px

  svg
    font-size: 18px
    color: #1d39c4

.timesContainer
  display: grid !important
  grid-gap: 1rem
  grid-template-columns: repeat(3, 1fr)
  margin-bottom: 1.5rem

  > span
    cursor: pointer
    border-radius: 12.5rem


.blueButton
  @include fontNormal
  font-size: .875rem
  background-color: rgba(187, 221, 244, 0.68)
  padding: 8px
  min-width: fit-content
  @include fullFlex


.btn
  border-radius: .5rem !important
  cursor: pointer !important
  padding: 16px !important
  font-size: .92rem !important

.activeButton
  background-color: #0C4CFC
  color: white

.summaryText
  margin: 2rem 0

  > p
    font-size: 1rem
    margin-bottom: 1.5rem

  * p
    font-size: 1rem
    margin-left: 1.5rem
    margin-bottom: .7rem

.precentageBtns
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 20px
  width: 100%

.precentageInput
  display: flex
  align-items: center
  font-size: .95rem

.stepBtns
  display: flex
  align-items: center
  justify-content: flex-end
  gap: 2rem
  align-self: flex-end
  margin-left: auto
  margin-top: 3rem

  .backBtn
    cursor: pointer
    color: #0C4CFC
    font-size: 16px
    display: flex
    align-items: center
    gap: .2rem
    transition: .3s all

    &:hover
      opacity: .7

  .nextBtn
    flex-direction: row !important
    display: flex !important
    justify-content: center !important
    width: 256px
    height: 48px
    @include fontNormal
    background-color: #0C4CFC !important
    color: white !important
    transition: all .2s linear
    @include fullFlex

    &:hover
      background-color: white !important
      color: #0C4CFC !important

.settingsLabel
  font-family: Poppins-Medium, serif

.loader
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  background-color: rgba(187, 222, 248, 0.1)

.loadingContainer
  width: 100%
  height: 100vh
  display: none
  position: absolute
  top: 0
  left: 0

  > img
    width: 70px
    height: 70px

.showLoading
  @include fullFlex
  background-color: rgba(187, 222, 248, 0.34)
  z-index: 1000


@media (max-width: 1200px)
  .timeLine
    margin-top: 2rem

@media (min-width: 520px) and (max-width: 1200px)
  .timeZoneBtn
    right: 16px
    top: 70px
    z-index: 999

@media (max-width: 800px)
  .settingBtns

    > button
      width: 210px !important
      font-size: .9rem !important

      > a
        font-size: .9rem !important

        svg
          font-size: 1rem !important

@media (max-width: 600px)
  .main
    margin-top: 0 !important
  .settingBtns
    > button
      width: 130px !important

      > a
        font-size: .8rem !important

        svg
          display: none !important

@media (max-width: 720px)
  .timesContainer
    grid-template-columns: repeat(2, 1fr)
  .stepOne

    .timePickers
      gap: 14px
  .stepTwo
    > div
      &:nth-of-type(1)
        gap: 14px

        > div
          gap: 14px
  .stepThree
    > div
      &:nth-of-type(1)
        gap: 14px
  .timePickers
    gap: 14px
  .precentageBtns
    grid-gap: 14px
  .stepFour
    > div
      &:nth-of-type(1)
        gap: 14px

        > div
          &:nth-of-type(1)
            width: 50%

            span
              padding: 10px 25px !important

      &:nth-of-type(2)
        gap: 14px
  .main
    .title
      font-size: 1.1rem
  //.timeItem
  //  width: 120px
  .timeStamp
    padding: 0
  .status
    padding: 0 10px
  .overViewWrapper
    .nextBtn
      > button
        width: 150px
        height: 38px
        font-size: 14px
  .stepOne
    .nextBtn
      > button
        width: 70px
        height: 38px
        font-size: 14px
  .stepTwo
    .nextBtn
      > button
        width: 70px
        height: 38px
        font-size: 14px
  .stepThree
    .nextBtn
      > button
        width: 70px
        height: 38px
        font-size: 14px
  .stepFour
    .nextBtn
      > button
        width: 70px
        height: 38px
        font-size: 14px


@media (max-width: 650px)
  .summary
    margin: 0 .5rem
  .summaryText
    * p
      margin: 0 .5rem .7rem
  .btn
    padding: 16px 10px !important
    font-size: 14px !important

@media (max-width: 520px)
  .timeZoneBtn
    right: 16px
    top: 120px
    z-index: 999
  .status
    span
      display: none

  .timePickers
    > div
      flex-direction: column
      align-items: inherit !important

@media (min-width: 481px)
  .mobileVersion
    display: none

@media (max-width: 480px)
  .desktopVersion
    display: none
  .stepBtns
    .nextBtn
      width: 150px
      font-size: 14px
      height: 40px

    .backBtn
      font-size: 14px

@media (max-width: 420px)
  .btn
    font-size: 12px !important

@media (max-width: 400px)
  .timeItem
    h4
      font-size: 12px        