.dropdown {
    width: fit-content;
}

.container :global(.ant-dropdown-menu) {
    max-height: 320px;
    overflow-y: scroll;
    min-width: 195px !important;
}

.container :global(.ant-dropdown-menu-title-content) {
    display: inline-block !important;
}

.container :global(.ant-dropdown-menu-submenu-vertical .ant-dropdown-menu-title-content) {
    font-size: 13px !important;
}

.container :global(.ant-dropdown-menu-item-active) {
    background-color: rgba(150, 171, 234, 0.25) !important;
}

.strategyItem {
    display: flex;
    align-items: center;
    gap: .5rem;
    justify-content: space-between;
    flex-grow: 1;
}

.strategyInfo {
    display: flex;
    flex-direction: column;
    gap: .2rem;
}

.infoBox {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.infoTitle {
    font-family: Poppins-Medium, sans-serif;
    font-size: 13px;
}

.strategyName {
    font-size: 13px;
}

.tag, .label {
    font-size: 12px;
    color: #666666;
    padding: 0 .5rem;
    border-radius: 1rem;
    width: fit-content;
    line-height: 1.3;
}

.label {
    background-color: #aad4ff;
}

.tag {
    background-color: #ffeaa7;
}

.strategyHandlers {
    display: flex;
    align-items: center;
    gap: .2rem;
}

.strategyHandlers span {
    cursor: pointer;
    transition: .3s all ease-in-out;
    padding: .1rem;
    border-radius: .3rem;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.strategyHandlers span:first-child {
    background-color: #0C4CFC;
    color: white;
}

.strategyHandlers span:first-child:hover {
    background-color: #072e97;
}

.strategyHandlers span:nth-child(2) {
    background-color: red;
    color: white;
}

.strategyHandlers span:nth-child(2):hover {
    background-color: #a70303;
}

.btn {
    transition: .3s all ease-in-out !important;
}

.systemBtn {
    background-color: #9370DB !important;
    border-color: #9370DB !important;
    color: white !important;
}

.systemBtn:focus, .systemBtn:hover, .systemBtn:active {
    background-color: white !important;
    color: #9370DB !important;
}

.userBtn {
    background-color: #FFA500 !important;
    color: white !important;
    border-color: #FFA500 !important;
}

.userBtn:hover, .userBtn:active, .userBtn:focus {
    background-color: white !important;
    color: #FFA500 !important;
}

@media (min-width: 480px) {
    .strategyItem {
        width: 300px;
    }
}

@media (max-width: 480px) {
    .infoTitle {
        display: none;
    }
}